<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item"><router-link to="/service">提供サービス</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">portato</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); background-color:rgba(255,255,255,0.5); background-blend-mode:lighten; padding: 18px 0 10px 0;"><h3><b>portato</b></h3></div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5>portato（ポルタート）は、capoの明細書をオンラインで送付するためのシステムです</h5>
        <p class="text-danger text-decoration-underline" style="margin: 0;">※portatoは、次世代版であるrights.services GREENの稼働に伴い、新規利用の申込を停止しています</p>
        <p>※オンライン明細をご希望の場合には、<router-link to="/service/green" style="margin: 0;">rights.services GREEN</router-link>をご検討ください</p>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【主な機能】</h5>
      </div>
      <div class="row text-start">
        <ol class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-person fa-fw" style="margin-right: 5px;"></i>明細送付先の管理</div>
              支払明細書の送付先を管理する機能です。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-people-arrows-left-right fa-fw" style="margin-right: 5px;"></i>capo連動機能</div>
              送付先とcapoの権利者を紐付ける機能です。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-paper-plane fa-fw" style="margin-right: 5px;"></i>明細書送付機能</div>
              明細書を送付先に送付する機能です。受領者は、メールで通知を受け、画面やPDFで明細書を確認することができます。
            </div>
          </li>
        </ol>
        <div class="row text-start" style="margin-top: 5px;">
          <span>※機能の詳細を知りたい、デモを実施して欲しい、テストで使用してみたい等のご要望は<router-link to="/inquiry">問い合わせページ</router-link>より申し付けください</span>
        </div>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
