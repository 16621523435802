<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">
        <img class="d-none d-md-block" src="../assets/logo.png" alt="" height="35">
        <img class="d-block d-md-none" src="../assets/logo.png" alt="" height="30">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/service" class="nav-link">提供サービス</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/company" class="nav-link">会社概要</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/recruit" class="nav-link">採用</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/inquiry" class="nav-link">問い合わせ</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
