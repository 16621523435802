<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

#app {
  font-family: 'Nunito', 'Kiwi Maru', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

blockquote {
  position: relative;
  margin: 2em 0;
  padding: 0 0 0 1.5em;
  font-size: .9em;
  border-left: 5px solid #CCC;
  border-radius: 2px;
  font-style: italic;
}
.form-label {
  font-size: 85%;
  color: #999999;
  margin-bottom: 0!important;
}
.form-value {
  margin-bottom: 8px;
}
.form-edit-line {
  margin-bottom: 10px;
}
.form-explanation {
  font-size: 85%;
  color: #999999;
}
</style>
