<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">提供サービス</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); padding: 18px 0 10px 0;" class="text-white"><h3><b>提供サービス</b></h3></div>
      <div class="row text-start" style="margin-top: 30px;">
        <h4>【システムの提供】</h4>
      </div>
      <div class="row text-start">
        <ol class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold fs-5">capo</div>
              著作権再分配システム
            </div>
            <router-link to="/service/capo" class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"><i class="fas fa-arrow-right"></i></router-link>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold fs-5">contano</div>
              原盤印税計算システム
            </div>
            <router-link to="/service/contano" class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"><i class="fas fa-arrow-right"></i></router-link>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold fs-5">portato</div>
              capo向けオンライン明細システム
            </div>
            <router-link to="/service/portato" class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"><i class="fas fa-arrow-right"></i></router-link>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold fs-5"><img src="../assets/rights.services.png" style="max-height: 22px;"></div>
              次世代統合管理システム
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto" style="padding-left: 30px;">
              <div class="fw-bold fs-5" style="color: #0097E0;">BLUE</div>
              音楽出版社向け管理
            </div>
            <router-link to="/service/blue" class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"><i class="fas fa-arrow-right"></i></router-link>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto" style="padding-left: 30px;">
              <div class="fw-bold fs-5" style="color: #E83821;">RED</div>
              原盤管理
            </div>
            <router-link to="/service/red" class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"><i class="fas fa-arrow-right"></i></router-link>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto" style="padding-left: 30px;">
              <div class="fw-bold fs-5" style="color: #EE86A8;">PINK</div>
              音楽以外の印税管理
            </div>
            <router-link to="/service/pink" class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"><i class="fas fa-arrow-right"></i></router-link>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto" style="padding-left: 30px;">
              <div class="fw-bold fs-5" style="color: #8EC31F;">GREEN</div>
              オンライン明細
            </div>
            <router-link to="/service/green" class="btn btn-outline-primary btn-sm" style="margin-top: 10px;"><i class="fas fa-arrow-right"></i></router-link>
          </li>
        </ol>
      </div>
      <div class="row text-start" style="margin-top: 50px;">
        <h4>【印税計算業務 作業代行】</h4>
      </div>
      <div class="row text-start">
        <ol class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div>著作権再分配業務 作業代行</div>
            </div>
            <router-link to="/service/agent/copyright" class="btn btn-outline-primary btn-sm"><i class="fas fa-arrow-right"></i></router-link>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div>原盤分配業務 作業代行</div>
            </div>
            <router-link to="/service/agent/master" class="btn btn-outline-primary btn-sm"><i class="fas fa-arrow-right"></i></router-link>
          </li>
        </ol>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
