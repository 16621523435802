<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">採用</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); padding: 18px 0 10px 0;" class="text-white"><h3><b>採用</b></h3></div>
      <div class="text-start" style="margin-top: 30px;">
        <div class="row">
          <div class="form-edit-line">
            現在募集中の職種はありません
          </div>
        </div>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
