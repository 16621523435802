<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item"><router-link to="/service">提供サービス</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">GREEN</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); background-color:rgba(255,255,255,0.5); background-blend-mode:lighten; padding: 18px 0 10px 0;" class="text-white"><h3><b><img src="../../assets/rights.services.png" style="max-height: 25px;"><span style="color: #8EC31F;">GREEN</span></b></h3></div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5>rights.services GREENは、当社全システムと連動するオンライン明細システムです</h5>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【主な機能】</h5>
      </div>
      <div class="row text-start">
        <ol class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-person fa-fw" style="margin-right: 5px;"></i>明細送付先の管理</div>
              支払明細書等の送付先を管理する機能です。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-people-arrows-left-right fa-fw" style="margin-right: 5px;"></i>当社システム連動機能</div>
              送付先とcapo/contanoの権利者を紐付ける機能です。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-paper-plane fa-fw" style="margin-right: 5px;"></i>明細書送付機能</div>
              明細書を送付先に送付する機能です。受領者は、メールで通知を受け、検索可能な画面やPDFで明細書を確認することができます。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-file-export fa-fw" style="margin-right: 5px;"></i>外部ファイル送付機能</div>
              当社システムで作成された明細書以外のファイルを送付先に送付する機能です。受領者は、メールで通知を受けることができます。
            </div>
          </li>
        </ol>
        <div class="row text-start" style="margin-top: 5px;">
          <span>※機能の詳細を知りたい、デモを実施して欲しい、テストで使用してみたい等のご要望は<router-link to="/inquiry">問い合わせページ</router-link>より申し付けください</span>
        </div>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【価格】</h5>
      </div>
      <div class="row text-start">
        月額0円～
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <img src="../../assets/green/free.png" style="max-width: 80px; margin-bottom: 10px;">
                <h5 class="card-title fw-bold fs-4">Free Plan</h5>
                <div class="card-text">
                  <p class="fs-4" style="margin-bottom: 0;">0円</p>
                  <p style="margin-bottom: 10px; font-size: 90%;">一ヶ月あたり</p>
                  <p class="text-start" style="margin-bottom: 10px; font-size: 90%;">以下の条件を<b>全て</b>満たしている場合</p>
                  <ul style="font-size: 90%; margin: 0; padding-left: 20px;" class="text-start">
                    <li>著作権印税、原盤印税の<b>どちらか</b>のみ</li>
                    <li>送付先<b>100件</b>未満</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <img src="../../assets/green/bronze.png" style="max-width: 80px; margin-bottom: 10px;">
                <h5 class="card-title fw-bold fs-4">Bronze Plan</h5>
                <div class="card-text">
                  <p class="fs-4" style="margin-bottom: 0;">5,000円</p>
                  <p style="margin-bottom: 10px; font-size: 90%;">一ヶ月あたり</p>
                  <p class="text-start" style="margin-bottom: 10px; font-size: 90%;">以下の<b>いずれか</b>に合致する場合</p>
                  <ul style="font-size: 90%; margin: 0; padding-left: 20px;" class="text-start">
                    <li>著作権印税、原盤印税<b>共に使用</b>で、送付先<b>100件未満</b></li>
                    <li>著作権印税、原盤印税の<b>どちらかのみ</b>で、送付先<b>100件以上1,000件未満</b></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <img src="../../assets/green/silver.png" style="max-width: 80px; margin-bottom: 10px;">
                <h5 class="card-title fw-bold fs-4">Silver Plan</h5>
                <div class="card-text">
                  <p class="fs-4" style="margin-bottom: 0;">10,000円</p>
                  <p style="margin-bottom: 10px; font-size: 90%;">一ヶ月あたり</p>
                  <p class="text-start" style="margin-bottom: 10px; font-size: 90%;">以下の<b>いずれか</b>に合致する場合</p>
                  <ul style="font-size: 90%; margin: 0; padding-left: 20px;" class="text-start">
                    <li>著作権印税、原盤印税<b>共に使用</b>で、送付先<b>100件以上1,000件未満</b></li>
                    <li>著作権印税、原盤印税の<b>どちらかのみ</b>で、送付先<b>1,000件以上3,000件未満</b></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <img src="../../assets/green/gold.png" style="max-width: 80px; margin-bottom: 10px;">
                <h5 class="card-title fw-bold fs-4">Gold Plan</h5>
                <div class="card-text">
                  <p class="fs-4" style="margin-bottom: 10px;">ご相談</p>
                  <p style="margin-bottom: 10px; font-size: 90%;">Silver Planまでに収まらない件数の場合、個別にお見積もりいたします</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【オプション】</h5>
      </div>
      <div class="row text-start">
        メッセージ送信機能（当社印税計算システムの支払明細書以外のファイルを送信可能）
      </div>
      <div class="row text-start">
        Free Plan : 月額1,500円、Bronze Plan : 月額1,000円、Silver Plan / Gold Plan : 無料
      </div>
      <div class="row text-start" style="margin-top: 20px;">
        <span style="margin: 0; padding: 0;">※お見積もりをご希望の際には、<router-link to="/inquiry">問い合わせページ</router-link>よりお問い合わせください</span>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
