<template>
  <div>
    <nav-component></nav-component>
    <div class="card" style="margin-top: 61px;">
      <img src="../assets/top_bg.jpg">
      <div class="card-img-overlay text-start text-white">
        <h2 class="card-title d-block d-md-none"><b>Tokyo Rights Service</b></h2>
        <h1 class="card-title d-none d-md-block d-lg-none" style="margin-top: 5px; margin-left: 30px;"><b>Tokyo Rights Service</b></h1>
        <h1 class="card-title d-none d-lg-block" style="margin-top: 50px; margin-left: 30px;"><b>Tokyo Rights Service</b></h1>
        <h3 class="card-title d-none d-md-block" style="margin-left: 30px;"><b>東京ライツサービス株式会社</b></h3>
        <div class="row d-block d-md-none">
          <div class="col-9">
            <p class="card-text" style="margin-top: 10px; font-size: 110%;">東京ライツサービスでは、著作権・著作隣接権の印税計算システム、業務サポートを提供しています</p>
          </div>
        </div>
        <div class="row d-none d-md-block" style="margin-left: 25px;">
          <div class="col-7">
            <p class="card-text" style="margin-top: 40px; font-size: 120%; margin-bottom: 10px;">東京ライツサービスでは、著作権再分配システム、原盤等著作隣接権の印税計算システム、オンライン明細システム等、印税計算に関するあらゆるシステムと、印税計算に関する業務サポートを提供しています</p>
            <p class="card-text" style="font-size: 120%;">システムのご説明、デモ、試用をご希望の場合には、お気軽にお問い合わせください</p>
          </div>
        </div>
        <div class="row d-none d-xl-table" style="margin-top: 30px;">
          <router-link to="/service" class="btn btn-primary" style="margin-left: 50px;"><i class="fas fa-circle-info" style="margin-right: 5px;"></i>提供サービスの詳細</router-link>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="text-start" style="margin-top: 40px;">
        <div class="row">
          <h4><b>お知らせ</b></h4>
        </div>
        <table class="table table-responsive table-striped">
          <tbody>
            <tr v-for="message in messageList" :key="message.id">
              <td style="border-bottom: 0;">{{message.publishedAt}}</td>
              <td style="border-bottom: 0;">
                <a @click.prevent.stop="selectMessage(message.id)" href="#">{{message.title}}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
    <div class="modal" tabindex="-1" ref="messageModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{message.title}}</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">日付</div>
            <div class="form-edit-line">
              {{message.publishedAt}}
            </div>
            <div class="form-label align-bottom">内容</div>
            <div class="form-edit-line">
              <quill-editor
                v-model:value="message.messageBody"
                :options="$quillRoOptions"
                disabled="disabled"
              />
            </div>
            <div v-if="message.attachmentList && message.attachmentList.length > 0" class="form-label align-bottom">添付</div>
            <div v-if="message.attachmentList && message.attachmentList.length > 0" class="form-edit-line">
              <table class="table table-responsive table-hover">
                <tbody>
                <tr v-for="(blob, index) in message.attachmentList" :key="blob.blob">
                  <td>{{blob.fileName}}</td>
                  <td style="width: 60px;"><button @click.prevent.stop="download(index)" type="button" class="btn btn-outline-primary btn-sm"><i class="fas fa-download"></i></button></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary" style="margin-right: 10px;"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '../components/Nav'
import axios from 'axios'
import { Modal } from 'bootstrap'

export default defineComponent({
  components: { navComponent },
  setup () {
    const messageResult = ref(null)
    const messageList = ref([])
    const message = ref({})
    const messageModalDiv = ref(null)
    let messageModal = null
    const selectMessage = (paramId) => {
      axios.get(process.env.VUE_APP_MONSTRS_API + '/message/' + paramId).then(response => {
        message.value = response.data
      })
      messageModal.show()
    }
    onMounted(() => {
      messageModal = new Modal(messageModalDiv.value, {
        keyboard: false
      })
      axios.get(process.env.VUE_APP_MONSTRS_API + '/message/list', {
        params: {
          published: true,
          page: 0,
          perPage: 10
        }
      }).then(response => {
        messageResult.value = response.data
        messageList.value = messageResult.value.list
      })
    })
    const download = async (index) => {
      const blob = message.value.attachmentList[index]
      const response = await axios.get(process.env.VUE_APP_MONSTRS_API + '/attachment/url/dl', {
        params: { blobName: blob.blobName }
      })
      const a = document.createElement('a')
      a.href = response.data
      a.click()
    }
    return {
      messageResult,
      messageList,
      message,
      messageModalDiv,
      selectMessage,
      download
    }
  }
})
</script>
