<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">問い合わせ</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); padding: 18px 0 10px 0;" class="text-white"><h3><b>問い合わせ</b></h3></div>
      <div style="margin-top: 5px;">（<span class="badge bg-danger" style="margin-right: 5px;">必</span> : 必ず入力してください）</div>
      <div class="text-start" style="margin-top: 30px;">
        <div class="row">
          <div class="form-label align-bottom">
            <span class="badge bg-danger" style="margin-right: 5px;">必</span>お名前
          </div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="form-label align-bottom">
            <span class="badge bg-danger" style="margin-right: 5px;">必</span>メールアドレス
          </div>
          <div class="form-edit-line">
            <input v-model="form.email" type="email" class="form-control">
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="form-label align-bottom">
            電話番号
          </div>
          <div class="form-edit-line">
            <input v-model="form.tel" type="text" class="form-control">
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="form-label align-bottom">
            会社名
          </div>
          <div class="form-edit-line">
            <input v-model="form.companyName" type="text" class="form-control">
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="form-label align-bottom">
            <span class="badge bg-danger" style="margin-right: 5px;">必</span>問い合わせの対象（複数選択可）
          </div>
          <div class="form-edit-line">
            <div class="form-check">
              <input v-model="form.typeCopyrightSystem" class="form-check-input" type="checkbox" value="" id="copySystem">
              <label class="form-check-label" for="copySystem">
                著作権再分配システムについて
              </label>
            </div>
            <div class="form-check">
              <input v-model="form.typeMasterSystem" class="form-check-input" type="checkbox" value="" id="masterSystem">
              <label class="form-check-label" for="masterSystem">
                著作隣接権（原盤）分配システムについて
              </label>
            </div>
            <div class="form-check">
              <input v-model="form.typeOnlineStatement" class="form-check-input" type="checkbox" value="" id="statementSystem">
              <label class="form-check-label" for="statementSystem">
                オンライン明細システムについて
              </label>
            </div>
            <div class="form-check">
              <input v-model="form.typeAgent" class="form-check-input" type="checkbox" value="" id="agency">
              <label class="form-check-label" for="agency">
                分配業務作業代行について
              </label>
            </div>
            <div class="form-check">
              <input v-model="form.typeOther" class="form-check-input" type="checkbox" value="" id="other">
              <label class="form-check-label" for="other">
                その他
              </label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="form-label align-bottom">
            <span class="badge bg-danger" style="margin-right: 5px;">必</span>問い合わせ内容
          </div>
          <div class="form-edit-line">
            <textarea v-model="form.notes" class="form-control" rows="5"></textarea>
          </div>
        </div>
        <div class="row" style="margin-top: 20px;">
          <button @click="save" class="btn btn-primary btn-lg"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>送信する</button>
        </div>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { Toast, Modal } from 'bootstrap'
import navComponent from '../components/Nav'
import axios from 'axios'

export default defineComponent({
  components: { navComponent },
  setup () {
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const form = ref({})
    const save = () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (!form.value.email) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        if (!validateEmail(form.value.email)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (!form.value.typeCopyrightSystem && !form.value.typeMasterSystem && !form.value.typeOnlineStatement && !form.value.typeAgent && !form.value.typeOther) {
        errors.value.push('問い合わせの対象から最低一つは選択してください')
      }
      if (!form.value.notes) {
        errors.value.push('問い合わせ内容を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        const title = []
        if (form.value.typeCopyrightSystem) {
          title.push('著作権再分配システムについて')
        }
        if (form.value.typeMasterSystem) {
          title.push('著作隣接権（原盤）分配システムについて')
        }
        if (form.value.typeOnlineStatement) {
          title.push('オンライン明細システムについて')
        }
        if (form.value.typeAgent) {
          title.push('分配業務作業代行について')
        }
        if (form.value.typeOther) {
          title.push('その他')
        }
        processMessage.value = '送信中です。このままでお待ちください。'
        processModal.show()
        axios.post(process.env.VUE_APP_MONSTRS_API + '/inquiry', {
          email: form.value.email,
          name: form.value.name,
          tel: form.value.tel,
          companyName: form.value.companyName,
          title: title.join(' / '),
          notes: form.value.notes
        }).then(() => {
          init()
          processModal.hide()
          messages.value = ['送信されました。']
          messageToast.show()
        })
      }
    }
    function init () {
      form.value = {
        name: '',
        email: '',
        tel: '',
        companyName: '',
        typeCopyrightSystem: false,
        typeMasterSystem: false,
        typeOnlineStatement: false,
        typeAgent: false,
        typeOther: false,
        notes: ''
      }
    }
    function validateEmail (email) {
      const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
      return reg.test(email)
    }
    onMounted(() => {
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      init()
    })
    return {
      messages,
      messageToastDiv,
      errors,
      errorToastDiv,
      processMessage,
      processModalDiv,
      form,
      save
    }
  }
})
</script>
