<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item"><router-link to="/service">提供サービス</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">capo</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); background-color:rgba(255,255,255,0.5); background-blend-mode:lighten; padding: 18px 0 10px 0;"><h3><b>capo</b></h3></div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5>capo（カーポ）は、音楽出版社向けに提供されている、著作権再分配計算等の機能を有した管理システムです</h5>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【主な機能】</h5>
      </div>
      <div class="row text-start">
        <ol class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-list-music fa-fw" style="margin-right: 5px;"></i>作品届作成</div>
              一般社団法人日本音楽著作権協会（JASRAC）、株式会社NexToneへ提出する作品届を出力する機能です。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-copyright fa-fw" style="margin-right: 5px;"></i>著作権譲渡契約書(&copy;MPA)作成</div>
              一般社団法人日本音楽出版社協会（MPA）策定の著作権譲渡契約書を出力する機能です。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-calculator-simple fa-fw" style="margin-right: 5px;"></i>再分配計算機能</div>
              JASRAC、NexTone等の分配データと著作権譲渡契約書のために入力された情報を元に再分配計算を行う機能です。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-memo-circle-info fa-fw" style="margin-right: 5px;"></i>支払明細書出力機能</div>
              権利者への支払明細書を出力する機能です。<router-link to="/service/green">オンライン明細システム</router-link>との連動により、オンラインで明細書を配布することもできます。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-money-bills fa-fw" style="margin-right: 5px;"></i>収支機能</div>
              楽曲別、アーティスト別等様々な切り口での収支計算とその結果確認をする機能です。
            </div>
          </li>
        </ol>
        <div class="row text-start" style="margin-top: 5px;">
          <span>※機能の詳細を知りたい、デモを実施して欲しい、テストで使用してみたい等のご要望は<router-link to="/inquiry">問い合わせページ</router-link>より申し付けください</span>
        </div>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【価格】</h5>
      </div>
      <div class="row text-start">
        月額13,000円～
      </div>
      <div class="row text-start" style="margin-top: 5px;">
        <span style="margin: 0; padding: 0;">※お見積もりをご希望の際には、<router-link to="/inquiry">問い合わせページ</router-link>よりお問い合わせください</span>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
