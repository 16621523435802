<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item"><router-link to="/service">提供サービス</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">著作権再分配業務 作業代行</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); background-color:rgba(255,255,255,0.5); background-blend-mode:lighten; padding: 18px 0 10px 0;"><h3><b>著作権再分配業務 作業代行</b></h3></div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5>著作権再分配計算に関わる作業を当社にて代行するサービスです</h5>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【代行可能範囲】</h5>
      </div>
      <div class="row text-start">
        <ol class="list-group list-group-numbered list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold">楽曲情報の登録・修正</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold">契約書情報の登録・修正</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold">その他情報の登録・修正</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold">分配計算の実施とエラー確認</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold">分配エラーの修正</div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 0;">
            <div class="ms-2 me-auto">
              <div class="fw-bold">分配結果確認</div>
            </div>
          </li>
        </ol>
        <div class="row text-start" style="margin-top: 5px;">
          <span>※詳細を知りたい等のご要望は<router-link to="/inquiry">問い合わせページ</router-link>より申し付けください</span>
        </div>
      </div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5 style="padding-left: 0;">【価格】</h5>
      </div>
      <div class="row text-start">
        <span style="margin: 0; padding: 0;">※お見積もりをご希望の際には、<router-link to="/inquiry">問い合わせページ</router-link>よりお問い合わせください</span>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
