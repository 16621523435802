<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item"><router-link to="/service">提供サービス</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">RED</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); background-color:rgba(255,255,255,0.5); background-blend-mode:lighten; padding: 18px 0 10px 0;" class="text-white"><h3><b><img src="../../assets/rights.services.png" style="max-height: 25px;"><span style="color: #E83821;">RED</span></b></h3></div>
      <div class="row text-start" style="margin-top: 30px;">
        <h5>rights.services REDは、原盤印税計算を中心とした次世代型原盤管理システムです</h5>
      </div>
      <div class="row text-start" style="margin-top: 30px; margin-bottom: 10px;">
        <h5 style="padding-left: 0; font-weight: bold;" class="text-primary">印税計算システムのわずらわしさからの解放</h5>
      </div>
      <div class="row text-start">
        <ol class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-timer fa-fw" style="margin-right: 5px;"></i>「分配計算」からの解放</div>
              リアルタイムでエラーチェック、分配計算が行われるため、「分配計算」で待たされることはありません。
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 20px;">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-binary-circle-check fa-fw" style="margin-right: 5px;"></i>「分配結果確認」からの解放</div>
              印税率等の修正はリアルタイムで計算結果に反映され、計算根拠をすぐに詳しく確認できます。
              <img src="../../assets/red/result.png" style="max-width: 600px; border: 1px solid #666666;">
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start" style="padding-top: 20px;">
            <div class="ms-2 me-auto">
              <div class="fw-bold"><i class="fas fa-pen-to-square fa-fw" style="margin-right: 5px;"></i>「エラー修正」からの解放</div>
              AIが修正候補を提示します。候補がない場合の登録作業もワンクリックで実施可能です。
              <div>
                <img src="../../assets/red/match.png" style="max-width: 400px; border: 1px solid #666666;">
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div class="row text-start" style="margin-top: 20px;">
        <p style="margin: 0;">※<span class="fs-5">2022年12月</span>から提供を開始します。もうしばらくお待ちください。</p>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
