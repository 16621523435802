<template>
  <div>
    <nav-component></nav-component>
    <div class="container" style="margin-top: 70px;">
      <div class="d-none d-md-block" style="height: 10px;"></div>
      <nav aria-label="breadcrumb" style="padding: 0;">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">会社概要</li>
        </ol>
      </nav>
      <div style="background-image: url('/header_bg.jpg'); padding: 18px 0 10px 0;" class="text-white"><h3><b>会社概要</b></h3></div>
      <div class="row" style="margin-top: 30px;">
        <div class="d-none d-md-block col-md-2 text-end" style="margin-top: 3px;">名称</div>
        <div class="d-block d-md-none text-start">名称</div>
        <div class="col-md-10 text-start fs-5">
          東京ライツサービス株式会社
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="d-none d-md-block col-md-2 text-end">所在地</div>
        <div class="d-block d-md-none text-start">所在地</div>
        <div class="col-md-10 text-start fs-6">
          東京都渋谷区代々木一丁目30番14号 天翔オフィス代々木ANNEX207<br>
          <a href="https://goo.gl/maps/tgiKgLxA24YU6BG89" target="_blank" class="btn btn-outline-primary btn-sm"><i class="fas fa-map-location" style="margin-right: 5px;"></i>地図</a>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="d-none d-md-block col-md-2 text-end">設立</div>
        <div class="d-block d-md-none text-start">設立</div>
        <div class="col-md-10 text-start fs-6">
          2018年6月
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="d-none d-md-block col-md-2 text-end">資本金</div>
        <div class="d-block d-md-none text-start">資本金</div>
        <div class="col-md-10 text-start fs-6">
          5,000,000円
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="d-none d-md-block col-md-2 text-end">代表者</div>
        <div class="d-block d-md-none text-start">代表者</div>
        <div class="col-md-10 text-start fs-6">
          代表取締役 松崎史崇
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="d-none d-md-block col-md-2 text-end">主な事業内容</div>
        <div class="d-block d-md-none text-start">主な事業内容</div>
        <div class="col-md-10 text-start fs-6">
          <ul style="margin-left: 0; padding-left: 17px; margin-bottom: 0;">
            <li>各種印税管理システムの開発</li>
            <li>各種印税管理システムの運営</li>
            <li>印税計算業務の作業代行</li>
          </ul>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="d-none d-md-block col-md-2 text-end">主な取引先<br>（一部）</div>
        <div class="d-block d-md-none text-start">主な取引先<br>（一部）</div>
        <div class="col-md-10 text-start fs-6">
          <ul style="margin-left: 0; padding-left: 17px; margin-bottom: 0;">
            <li>株式会社ABCフロンティア</li>
            <li>合同会社DMM.com</li>
            <li>株式会社EMP</li>
            <li>株式会社JRCアカウンティング</li>
            <li>株式会社LDH music&publishing</li>
            <li>株式会社NHK出版</li>
            <li>株式会社ROOFTOP MUSIC</li>
            <li>株式会社SDR</li>
            <li>アソビミュージック株式会社</li>
            <li>株式会社アップフロント音楽出版</li>
            <li>株式会社インクストゥエンター</li>
            <li>株式会社オフィスオーガスタ</li>
            <li>有限会社雲母音楽出版</li>
            <li>株式会社スタジオジブリ</li>
            <li>株式会社スタジオポノック</li>
            <li>株式会社ティーズ音楽出版</li>
            <li>株式会社テレビ朝日ミュージック</li>
            <li>株式会社ネルケプランニング</li>
            <li>ビクターミュージックアーツ株式会社</li>
            <li>株式会社ポリスター</li>
            <li>株式会社電通ミュージック・アンド・エンタテインメント</li>
            <li>渡辺音楽出版株式会社</li>
          </ul>
        </div>
      </div>
    </div>
    <footer class="alert" style="padding-bottom: 40px; margin-top: 50px;">
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row" style="margin-bottom: 10px; margin-left: 8px;">
            <img src="../assets/logo.png" style="max-width: 250px;">
          </div>
          <div class="row" style="margin-left: 20px;">
            東京ライツサービス株式会社
          </div>
          <div class="row" style="margin-left: 20px;">
            〒151-0053 東京都渋谷区代々木1-30-14
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="mailto:info@rights.tokyo">info@rights.tokyo</a>
          </div>
          <div class="row text-start" style="margin-left: 8px;">
            <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
          </div>
        </div>
        <div class="col-md-6 justify-content-end">
          <hr class="d-block d-md-none">
          <div class="row justify-content-end text-end" style="margin-right: 20px;">
            既に当社サービスをご利用の方はこちら
          </div>
          <div class="row justify-content-end" style="margin-right: 8px; margin-top: 10px;">
            <img src="../assets/monstrs.png" style="max-width: 150px;">
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
          </div>
          <div class="row text-end" style="margin-right: 8px;">
            <a href="https://monstrs.rights.tokyo" target="_blank">https://monstrs.rights.tokyo</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navComponent from '../components/Nav'

export default {
  components: { navComponent },
  setup () {}
}
</script>
