import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueGtag from 'vue-gtag-next'
import { quillEditor } from 'vue3-quill'

const app = createApp(App)

app.use(VueGtag, {
  property: {
    id: 'G-1FT8MTWTDK'
  }
})
app.config.globalProperties.$quillOptions = {
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'image']
    ]
  }
}
app.config.globalProperties.$quillRoOptions = {
  modules: {
    toolbar: null
  }
}

app.use(router).use(quillEditor).mount('#app')
