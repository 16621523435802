import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import Home from '../views/Home.vue'
import Company from '../views/Company.vue'
import Recruit from '../views/Recruit.vue'
import Inquiry from '../views/Inquiry.vue'
import Service from '../views/Service.vue'
import Capo from '../views/service/Capo'
import Contano from '../views/service/Contano'
import Portato from '../views/service/Portato'
import Blue from '../views/service/Blue'
import Red from '../views/service/Red'
import Pink from '../views/service/Pink'
import Green from '../views/service/Green'
import AgentC from '../views/service/AgentC'
import AgentM from '../views/service/AgentM'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/company',
    name: 'Company',
    component: Company
  },
  {
    path: '/recruit',
    name: 'Recruit',
    component: Recruit
  },
  {
    path: '/inquiry',
    name: 'Inquiry',
    component: Inquiry
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/service/capo',
    name: 'Capo',
    component: Capo
  },
  {
    path: '/service/contano',
    name: 'Contano',
    component: Contano
  },
  {
    path: '/service/portato',
    name: 'Portato',
    component: Portato
  },
  {
    path: '/service/blue',
    name: 'Blue',
    component: Blue
  },
  {
    path: '/service/red',
    name: 'Red',
    component: Red
  },
  {
    path: '/service/pink',
    name: 'Pink',
    component: Pink
  },
  {
    path: '/service/green',
    name: 'Green',
    component: Green
  },
  {
    path: '/service/agent/copyright',
    name: 'AgentC',
    component: AgentC
  },
  {
    path: '/service/agent/master',
    name: 'AgentM',
    component: AgentM
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

export default router
